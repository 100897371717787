<script setup>

import { ref, onMounted , watch, inject} from 'vue'
import { useToast } from 'vue-toastification'

import ChatInputv3 from '../ChatInputv3.vue';

const props = defineProps({
    debug: { type:Boolean, default:false }
})

const audioRef = ref(null)

const toast = useToast()
const checkToask = () => toast('check toast')

const speaker_manager = inject('globalSpeaker')

const testAudio = async ( idx = 0) =>{
    // console.log( speaker_manager.manager )
    speaker_manager.manager.selectDriver('cloud')
    console.log( 'driver is ', speaker_manager.manager.getDriver() )
    speaker_manager.manager.getDriver().cancel()
    // console.info('test audio called')
    // console.info( speaker_manager.manager.getDriver().voices )
    speaker_manager.manager.getDriver().selectVoice( idx )
    console.log( 'available cloud voices is ', speaker_manager.manager.getDriver().toArray() )
    // console.warn( 'get voice ', speaker_manager.manager.getDriver().getVoice().Id )
    // console.warn('get voices is ', speaker_manager.manager.getDriver().getVoices() )
    await speaker_manager.manager.getDriver().read('tem dias que o coração sai no peito man', () => console.info("audio start"), (error) => console.warn('audio error'))
}

const nativeTestAudio = async ( idx = 0 ) =>{
    console.info('native test audio called')
    speaker_manager.manager.getDriver().cancel()
    speaker_manager.manager.selectDriver('native')
    speaker_manager.manager.getDriver().selectVoice( idx )
    console.log( speaker_manager.manager.getDriver().toArray() )
    await speaker_manager.manager.getDriver().read('tem noites que tá tudo legal brother', ()=> console.info('native start'), ()=> console.warn('native end') )
}


onMounted( async () =>{
    // console.info( AWS_CREDENTIALS )
    console.log('welcome')
    console.log( speaker_manager )    
})

const recordingStates = {
    gravando:{ label:'gravando', classe: 'btn-danger'},
    desligado:{ label:'desligado', classe: 'btn-primary'}
}

const sandboxMessages = ref([])

const recording = ref(false)
const recordingState = ref( recordingStates.desligado )
const recordingContent = ref([])
const recordingChunk = ref('')

const recordingStart = ( evt ) =>{
    console.info(' recording START ', evt )
}
const recordingResult = ( evt ) =>{
    console.info('partial is ', evt?.results[0][0].transcript)
    recordingChunk.value = evt?.results[0][0].transcript
    // console.info(' recording RESULT ', evt )
}
const recordingEnd = ( evt ) =>{
    console.info(' recording END ', evt )
    if ( recording.value )
    {
        recordingContent.value.push( recordingChunk.value )
        recordingChunk.value = ''
        recorder.start()
    }
    else
    {
        sandboxMessages.value.push( recordingContent.value.join(' ') )
        recordingContent.value = []
    }
        
}

const SpeechRecorder = window.SpeechRecognition || window.webkitSpeechRecognition

// const recorder = new SpeechRecorder()
// recorder.lang = 'pt-BR'
// recorder.continuous = false
// recorder.interimResults = true 
// recorder.onstart = recordingStart
// recorder.onend = recordingEnd
// recorder.onresult = recordingResult

// import NativeFluidRecorder from './composables/drivers/recorder/NativeFluidRecorder';

import { useNativeRecorderv2 } from './composables/nativerecorderv2';

const fluidRecorder = new useNativeRecorderv2()

const fluidRecorderEnd = ( evt ) =>{
    console.info( 'recorder is now END')
    console.info( fluidRecorder.chunk.value )

    if ( recording.value )
    {
        recordingContent.value.push( fluidRecorder.chunk.value )
        fluidRecorder.chunk.value = ''
        fluidRecorder.start()
    }
    else
    {
        sandboxMessages.value.push( recordingContent.value.join(' ') )
        recordingContent.value = []
    }

}

const fluidRecorderStart = ( evt ) =>{
    console.info( 'recorder is now START')
    console.info( fluidRecorder.chunk.value )
}

fluidRecorder.recorder?.onEnd( fluidRecorderEnd )
fluidRecorder.recorder?.onStart( fluidRecorderStart )



// recorder
//     .onStart( recordingStart )
//     .onEnd( recordingEnd )
//     .onSimpleResult( (text) => recordingChunk.value = text  )

const toggleRecording = () =>{
    recording.value = ! recording.value
    recordingState.value = ( recording.value) ? recordingStates.gravando : recordingStates.desligado

    // console.log( fluidRecorder )
    if (fluidRecorder.recorder )
    {
        fluidRecorder.stop()
        if ( recording.value )
            fluidRecorder.start()    
    }
}

const cleanSandboxMessages = () => sandboxMessages.value = []

</script>
<template>
    <div id='sandbox' class='free-bird bg-dark rounded' style="font-size:1rem" v-show='props.debug' >
        <div class='p-2 center-flex flex-row gap-3'>

            <span>sandbox</span>
            <i class='fa fa-wifi'></i>
            <audio ref="audioRef" controls type='audio/mpeg'>
                <!-- <source ref='audioSourceRef' type='audio/mp3'> -->
            </audio>

            
        </div>
        <div class="border-top p-2">
            
            <ChatInputv3 :isLoading='false' />
            
            <div class='my-2'>
                <button 
                    class='btn me-2' 
                    :class='recordingState.classe' 
                    type='button' 
                    @click='toggleRecording' 
                    :disabled="fluidRecorder.noFeature.value"
                >
                    {{ recordingState.label }}
                </button>

                <button class='btn btn-secondary me-2' @click='cleanSandboxMessages'>limpar conversa</button>

            </div>

            <div 
                v-for="(message, idx ) in sandboxMessages" 
                :key="idx"
                class="p-2 text-bg-secondary my-2"
            >
                {{ message }}
            </div>


            <div class='p-2 border my-2 rounded'> 
                <span 
                    v-for="(chunk, idx ) in recordingContent" 
                    :key='idx'
                    class = 'me-2 text-light'
                >
                    {{ chunk }}
                </span>
                
                <span class='me-2 text-primary'>
                    {{  fluidRecorder.chunk }} 
                </span>
            </div>


        </div>


    </div>

</template>
<style scoped>

.free-bird
{
    position:absolute;
    top: 60px;
    left: 10px;
    z-index: 1000;
}

#sandbox
{
    padding: 10px;
    margin: 10px auto;
    border: 2px solid rgb(41, 41, 41);
    background-color: rgb(41, 41, 41);
    color:rgb(64, 213, 0);
    min-width: 80%;
    font-size: 1.2rem;

    opacity: .8;
}

#sandbox > *
{
    margin: 0 10px;
}

#sandbox select
{
    padding: 5px;
}



</style>