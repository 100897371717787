<template>
  <div class="chat-header">
    <h1>{{ title }}</h1>
    <button @click="$emit('clearChat')" class="clear-button">
      <i class="fas fa-trash-alt"></i> Limpar Histórico
    </button>
  </div>
</template>

<script>

const title = process.env.VUE_APP_NAME

export default {
  name: 'ChatHeader',
  data(){
    return {
      title,
    }
  }
};
</script>

<style scoped>
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;
}

.chat-header h1 {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.clear-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.clear-button i {
  margin-right: 5px;
}

.clear-button:hover {
  background-color: #004494;
}
</style>
