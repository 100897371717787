import ABCRecorder from './abcrecorder'

export default class NativeFluidRecorder extends ABCRecorder
{
    constructor( lang = 'pt-BR')
    {
        super( lang )
        this.recorder.continuous = false
        this.recorder.interimResults = true 

    }   

  
}