import { defineStore } from 'pinia'

const initial = { aws:{}, loaded: false }

export const useSoundStore = defineStore('sound-store', () => {
    const state = initial

    function load( payload )
    {
        this.state.aws = payload 
        this.state.loaded = true
        
        // console.info('soundStore credentials loaded!', this.state, this )
        return this.state

    }

    function getCredentials()
    {
        return this.state.aws
    }

   return {
    state,
    load,
    getCredentials,
   }
})