export default class ABCRecorder
{
    constructor( lang = 'pt-BR' )
    {
        this.lang = lang
        this.SpeechRecorderClass = window.SpeechRecognition || window.webkitSpeechRecognition
        console.info( this.SpeechRecorderClass )
        // faça as verificações de compatibilidade antes de usar
        this.recorder = new this.SpeechRecorderClass()
        this.recorder.lang = this.lang   
        console.info('recorder is ', this.recorder)           
    }

    start()
    {
        this.recorder.start()
        return this
    }
    
    stop()
    {
        this.recorder.stop()
        return this
    }
    

    onStart( callback )
    {
        this.recorder.onstart = callback
        return this
    }
    onEnd( callback )
    {
        this.recorder.onend = callback
        return this
    }

    onError( callback )
    {
        this.recorder.onerror = callback
        return this
    }
    onResult( callback )
    {
        this.recorder.onresult = callback
        return this
    }
 
    onSimpleResult( callback )
    {
        /* mesmo que onResult mas apenas manda o text transcrito - que é o que importa */
        this.recorder.onresult = ( evt ) =>{
            callback( evt?.results[0][0].transcript )
        }
        return this
    }
    
}