export default class ABCSpeakerDriverv2
{
    constructor( lang = 'pt-BR')
    {
        this.lang = lang
        this.voices = []
        this.voice = null

        this.speechOptions = {}
    }

    async loadVoices(){
        return this
    }
    getVoices(){
        return this.voices
    }
    getVoice(){
        return this.voice
    }
   
    selectVoice( idx )
    {
        if ( idx >= this.voices.length )
            idx = 0
        this.voice = this.voices[ idx ]

        return this.voice
    }

    toArray()
    {
        return this.getVoices()
    }
    
    setSpeechOptions( optionsData = {})
    {
        this.speechOptions = { ...this.speechOptions, ...optionsData}
        return this
    }

    isSpeaking()
    {
        return false
    }

    async read(message, onStart=null, onError=null)
    {
        throw new Error('ABCSpeakerDriver.read must be override')
    }

    cancel(){
        throw new Error('ABCSpeakerDriver.cancel must be override')
    }
}