<template>
  <footer class="app-footer">
    <p>CSU © 2023 - Todos direitos reservados</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.app-footer {
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

.app-footer p {
  margin: 0;
  font-size: 14px;
  color: #333;
}
</style>
