<script setup>
import { ref, onMounted } from 'vue'
import { useNativeRecorderv2 } from '../../composables/nativerecorderv2';

const emits = defineEmits(['onStart', 'onFinished', 'onRecording'])

const fluidRecorder = useNativeRecorderv2()
const isRecording = ref(false)
const recordingStates = {
    idle:{ icon: 'fas fa-microphone', classe: 'btn-primary' },
    recording:{ icon: 'fas fa-microphone-slash', classe:'btn-danger' },
    noRecording: {icon: 'fas fa-microphone-slash', classe: 'btn-secondary' },
}
const recordingState = ref( recordingStates.idle )
const recordingBuffer = ref([])


const intentRecording = () =>{
    // console.info('user  INTENT RECORDING ')
    
    isRecording.value = ! isRecording.value
    
    // console.log( isRecording.value )

    if ( isRecording.value )
    {
        fluidRecorder.chunk.value = ''        
        startRecording()
        recordingState.value = recordingStates.recording
        emits('onStart')
    }    
    else 
    {
        finishRecording()
        recordingState.value = recordingStates.idle
    }    
}

const startRecording = () =>{
    // console.info('recording START')
    fluidRecorder.start()

}

const endRecording = () =>{
    // console.info('recording END')
    // console.info( fluidRecorder.chunk.value )

    if( isRecording.value )
    {
        recordingBuffer.value.push( fluidRecorder.chunk.value )
        fluidRecorder.chunk.value = ''
        emits('onRecording', recordingBuffer.value.join(' ') )
        fluidRecorder.start()
    }

}

const finishRecording = () =>{
    // console.info('recording FINISHED ', recordingBuffer.value )
    emits('onFinished', recordingBuffer.value.join(' '))
    recordingBuffer.value = []
}

fluidRecorder.recorder?.onEnd( endRecording )


onMounted(() =>{
    // console.info('Recroder Button is ON')
    /* o button desativa sozinho se não tiver recorder feature */
    if ( fluidRecorder.noFeature.value )
        recordingState.value = recordingStates.noRecording
})


defineExpose({
    isRecording
})


</script>
<template>

        <button 
            class='btn'
            :class="recordingState.classe"
            @click='intentRecording'
            :disabled="fluidRecorder.noFeature.value"
        >
            <i :class='recordingState.icon'></i>
        </button>



</template>