import { ref, watch } from 'vue'
import { useToast } from "vue-toastification";

class NativeRecorder
{
    constructor( lang = 'pt-BR', options = { continuous: false, interimResults: false  })
    {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition; 
        // console.info( 'speeche recognition is on? ', SpeechRecognition )
        this.recorder = new SpeechRecognition()
        this.recorder.lang = lang
        this.recorder.continuous = options.continuous || false
        this.recorder.interimResults = options.interimResults || false
        this.transcript = ''

        this.onresult = null
        this.recorder.onresult = this._localOnResult.bind(this)
    }

    _localOnResult( event )
    {
        // console.log('onresult is ', event, this )

        this.transcript = event?.results[0][0].transcript
        
        console.info(`new transcription from NAtiveRecorder is ${ this.transcript } `)
        // recebe apena so texto transcrito
        if ( this.onresult )
            this.onresult( this.transcript )
    }

    start()
    {
        this.recorder.start()
        return this
    }

    stop()
    {
        this.recorder.stop()
        return this
    }

    onStart( callable ){
        this.recorder.onstart = callable
        return this
    }
    onEnd( callable ){
        this.recorder.onend = callable
        return this
    }
    onError( callable ){
        this.recorder.onerror = callable
        return this
    }
    
    onResult( callable ){
        this.onresult = callable
        return this
    }
}


export function useNativeRecorder( lang = 'pt-BR', onresult=null, onstart=null, onend=null, onerror=null)
{
    const toaster = useToast()
    const isRecording = ref(false)
    const toggleRecording = () => isRecording.value = ! isRecording.value

    let nativeRecorder = null
 
    const noFeature = ref(false)
    const noFeatureAlert = () => toaster.info('esse aparelho pode não dar bom suporte para gravação de mensagens')
    const recorderErrorAlert = () => toaster.error('erro na gravação')

    if (!(window.SpeechRecognition || window.webkitSpeechRecognition ))
    {
        noFeature.value = true
        noFeatureAlert()    
    }
    else
    {
        nativeRecorder = new NativeRecorder( lang )
        nativeRecorder
            .onStart(onstart)
            .onResult( onresult )
            .onEnd( (evt) => {
                if( onend )
                    onend( evt )
                toggleRecording() 
            } )
            .onError( (evt) => {
                if( onerror )
                    onerror(evt)
                toggleRecording()
                recorderErrorAlert() 
            })
    }

    /*
        teoricamente tudo controlado pelo isRecoding and toggleRecording
        o SpeechRecognition deposi do start dá end automaticamente
        precisa ter isso em mente pra controlar o state de isRecording

        porisso estou usando watcher aqui - desse jeito tudo fica amarrado apenas na ref isRecording

    */
    
    watch( isRecording, ( newValue, oldValue ) =>{
        console.log(`isRecording change state from ${oldValue} to ${newValue}`)

        if ( noFeature.value )
        {
            noFeatureAlert()
            return
        }
        else
        {
            if ( oldValue )
                nativeRecorder.stop()
            if( newValue )
                nativeRecorder.start()
        }
    })


    return {
        isRecording,
        toggleRecording,
        nativeRecorder,
        noFeature,
        noFeatureAlert,
    }
}