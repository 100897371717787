<script setup>

import { inject, ref, onMounted, computed } from 'vue'
import SoundProviderConfiguration from './SoundProviderConfiguration.vue';
import VoicesSelectField from './VoicesSelectField.vue';


const { manager } = inject('globalSpeaker')
const providers = ref(['native', 'cloud'])
const loading = ref(false)
const voices = ref( manager.getDriver().toArray() ) 

const toggleLoading = () => loading.value = ! loading.value 


const forceLoadOptions = () =>{
    voices.value = manager.getDriver().toArray()
    if ( voices.value.length > 0 )
        return true
    else
        setTimeout( () => forceLoadOptions(), 500 )
}

onMounted(() =>{
    forceLoadOptions()
    console.info( 'configuration vboices value is ', voices.value )
})



const onChangeProvider = ( providerName ) =>{
    manager.selectDriver( providerName )
    voices.value = manager.getDriver().toArray()
    // console.info(`speaker provider was changed to ${ providerName.toUpperCase() }`)    
}


const changeVoice = ( voice_index ) =>{
    // const voice_index = parseInt( evt.target.value )
    // console.log(`changeing SPEAKER to ${voice_index}`)
    // speaker.voiceManager.changeVoiceByIndex( voice_index )
    // console.log(`actula voice is ${ speaker.voiceManager.getVoice().name }`)
    if ( voice_index < 0)
        voice_index = 0
    // console.info('voice index is ', voice_index )
    manager.getDriver().selectVoice( voice_index )
}

const testVoice = async () =>{
    // console.log('test speaker')
    // speaker.read('essa é a nova voz que você configurou para o chat')
    toggleLoading()
    await manager.getDriver().read('essa é a nova voz do chat')
    setTimeout(()=> toggleLoading(), 1000)
}


</script>
<template>
    <SoundProviderConfiguration 
        title='sound settings'
        :labels="providers" 
        @onChangeProvider="onChangeProvider"
    >

        <div class='mb-2'>
            <div class='border border-warning dn-sound-provider-loading child-full-size child-overlay-10 center-flex d-none' v-show="loading">
                <div class='spinner-border' role="status"></div>
            </div>

            <div class='dn-sound-provider-options'>
                
                <VoicesSelectField :voices="voices" @onVoiceSelected="changeVoice" />
                
            </div>

        </div>
        <div class='text-end' v-if='voices.length'>
            <button class='btn btn-secondary btn-sm' @click="testVoice" :disabled="loading">
                <span v-if='loading'>
                    <span class='spinner-border spinner-border-sm me-2'></span>
                    <span>carregando</span>
                </span>
                <span v-else>
                    <i class='fas fa-volume-up me-2'></i>
                    <span>testar voz</span>
                </span>
                
            </button>
        </div>
    </SoundProviderConfiguration>
</template>
