import { Polly, SynthesizeSpeechCommand, DescribeVoicesCommand } from '@aws-sdk/client-polly'

import ABCSpeakerDriverv2 from './abcspeakerdriverv2'

export class AwsSpeakerDriverv2 extends ABCSpeakerDriverv2
{

    constructor( credentials, lang = 'pt-BR')
    {
        // console.warn('AWS SpeakerDriver loaded with credentials ', credentials )
        super( lang )
        this.pollyClient = new Polly( credentials )

        this.language_filters = { LanguageCode: this.lang }
        this.speechOptions = { OutputFormat: 'mp3' }
        this.audio = new Audio()
    }

    async loadVoices()
    {
        const response = await this.pollyClient.send(new DescribeVoicesCommand( this.language_filters ) ) 
        this.voices= response.Voices
        if ( !this.voice )
            this.voice = this.voices[0]
        
        // console.info('voice value is ', this.voices,  this.voice )
        // console.info('voice value is AFTER ', this.voice.value, this.voice )
        // console.info('VOICES[] value ', this.voices, this.voices.value, this.voices.value[0] )
            
        return this
    }

    toArray()
    {
        return this.voices.map( voice => voice.Id )
    }


    isSpeaking()
    {
        return ( this.audio.duration > 0 && this.audio.paused ) || this.audio.ended
    }

    async read( message, onStart = null, onError = null)
    {
        // const options = { ...this.speechOptions, VoiceId: this.voiceManager.getVoice()?.Id, Text: message }
        const options = { ...this.speechOptions, VoiceId: this.getVoice()?.Id, Text: message }
        // console.warn('polly options is ', options )
        // console.info( 'get voice is ', this.voiceManager )

        if( this.isSpeaking() )
            this.cancel()


        try
        {
            // console.info('options data is ', options)
            const response = await this.pollyClient.send( new SynthesizeSpeechCommand( options ))
            this.audio.src = await this._convertAudioStreamInURL( response.AudioStream )
            this.audio.load()
            if( onStart ) 
                onStart( this )
            this.audio.play() 
        }
        catch (error)
        {
            this.cancel()
            console.error( error )
            console.error( error.$metadata )
            if ( onError )
                onError( error.$metadata )
        }

        return this
    }

    cancel()
    {
        this.audio.pause()
        return this
    }

    async _convertAudioStreamInURL( audioStream , audioType = 'audio/mpeg')
    {
        const byteArray = await audioStream.transformToByteArray()
        const blob = new Blob([ byteArray ], { type: audioType })
        return URL.createObjectURL( blob ) 
    }
   
}