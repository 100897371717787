<script setup>

import { ref, onMounted, computed } from 'vue'
import { useToast } from 'vue-toastification'
import RecorderButton from './darknerd/components/buttons/RecorderButton.vue';

const props = defineProps({ 
    isLoading:{ type:Boolean, required: true }
})

const emits = defineEmits(['sendMessage'])
const toaster = useToast()

const containerRef= ref(null)
const inputRef = ref(null)
const message = ref('')
const recorderButtonRef = ref(null)

const gravando = 'Gravando ...'
const digite = 'Digite sua mensagem'


const sendMessage = ()=>{
    // console.info(`send message dude `, message.value )
    if ( message.value.trim() != '')
    {
        emits('sendMessage', message.value.trim() )
        message.value = ''
        updateInputCursor()
    }
}

const isBlocked = computed( () => props.isLoading  )

onMounted(() =>{
    // console.info('ChatInput v3 is ON')
    updatePlaceholder( digite )
    updateInputCursor()
})

const updateInputCursor = () => {
    // console.log( inputRef.value.focus )
    inputRef.value.focus()
    inputRef.value.setSelectionRange( message.value.length, message.value.length )
    // console.info( inputRef.value.setSelectionRange )
}

const updatePlaceholder = ( text ) =>{
    inputRef.value.setAttribute('placeholder', text)
}


const onRecorderStart = () => { 
    // console.info('chat input recroding start')
    message.value = ''
    updatePlaceholder(gravando)
}
const onRecorderFinished = ( text ) => {
    // console.warn('chat input finished ', text)
    updateInputCursor()
    updatePlaceholder( digite )
    
}
const onRecorderRecording = ( text ) => {
    // console.info('chat input Recording chunk is  ', text )
    message.value = text
    updateInputCursor()
}


</script>
<template>
    <div class='chat-input' ref="containerRef">
        <div class='input-group shadow'>
            
            <input 
                type="text" 
                class='form-control' 
                placeholder='Digite sua mensagem ...' 
                ref="inputRef" 
                v-model='message' 
                :disabled="isBlocked" 
                @keyup.enter='sendMessage'
            />

            <slot>
                <RecorderButton 
                    ref="recorderButtonRef" 
                    @onStart="onRecorderStart"
                    @onFinished="onRecorderFinished"
                    @onRecording='onRecorderRecording'
                />
            </slot>

            <button 
                class='btn btn-primary' 
                @click='sendMessage' 
                :disabled='isBlocked'
            >
                <i class='fas fa-paper-plane'></i>
            </button>

        </div>

    </div>
</template>
<style scoped >

.chat-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f1f1f1;
  border-radius: 0px 0px 10px 10px;
}

</style>