<script setup>

import { inject, ref, onMounted } from 'vue'

const props = defineProps({
    title: { type: String, default:'sound provider'},
    icon: { title:String, default:'fa-volume-up' },
    labels: { title:Array, required:true }
})

const emits = defineEmits(['onChangeProvider'])
const providerName = ref( props.labels[0] )
const { manager } = inject('globalSpeaker')


onMounted(() =>{
    providerName.value = manager.pointer
})



const changeProvider = ( newProvider ) =>{
    // console.log(`changing speaker provider to ${newProvider}`)
    providerName.value = newProvider
    manager.selectDriver( newProvider )
    // make little sense but oookay
    emits('onChangeProvider', providerName.value )
}



</script>
<template>

    <div class='container border border-1 border-secondary p-2 position-relative rounded'>
        <div class='d-flex align-items-center dn-sound-provider-title mb-3 border-bottom border-secondary p-2 mb-2'>
            <i class="fas me-2" :class='props.icon' ></i>
            <span class="fs-5">{{ title }}</span>
        </div>
        <div class='d-flex justify-content-center dn-sound-provider-controls mb-3'>
            <button 
                v-for="(label, idx) in props.labels" 
                :key="idx"
                @click='() => changeProvider(label)'
                class="btn btn-primary mx-1"
                :class="[ providerName == label ? 'active':'']"
            >
                {{ label }}
            </button>
        </div>
        <slot></slot>
    </div>

</template>
