<script setup>

import { inject, ref, onMounted } from 'vue'
import SoundProviderConfiguration from './SoundProviderConfiguration.vue';

const { manager } = inject('globalSpeaker')
const providers = ref(['native', 'cloud'])
const icon = 'fa-microphone'

const pointerRef = ref('native')

onMounted(() =>{
    // pointerRef.value = manager.pointer || 'native'
})

const onChangeProvider = ( newProvider ) =>{
    pointerRef.value = newProvider
    // console.info(`provider was changed to ${newProvider}`)
}

</script>
<template>
    <SoundProviderConfiguration 
        title='microphone settings'
        :icon="icon" 
        :labels="providers" 
        @onChangeProvider="onChangeProvider" 
    >
        <div class="border border-secondary text-secondary p-2 rounded center-flex">
            <span v-if="pointerRef == 'native'" class='text-success text-center'>
                <i class="fas fa-check-circle me-2"></i>
                <span>essa versão está estável</span>
            </span>
            <span v-if="pointerRef == 'cloud'" class='text-success text-center'>
                <i class="fas fa-check-circle me-2"></i>
                <span>essa versão está estável</span>    
                <!-- <i class="fas fa-exclamation-triangle me-2"></i>
                <span>versão experimental e pode causar instabilidades no chat</span>
             -->
            </span>
            
        </div>
    
    
    </SoundProviderConfiguration>
</template>
