import { ref } from 'vue'
import { useToast } from "vue-toastification";

import NativeFluidRecorder from './drivers/recorder/NativeFluidRecorder';


export function useNativeRecorderv2( lang = 'pt-BR' )
{
    const noFeature = ref( false )
    const toaster = useToast()
    const noFeatureAlert = () => toaster.info('esse aparelho pode não dar bom suporte para gravação de mensagens')
    // const recorderErrorAlert = () => toaster.error('erro na gravação')
    let recorder =  null
    const recorderChunk = ref('')

    
    const noFeatureDecorator = ( func ) =>{
        if( noFeature.value )
        {
            return () => noFeatureAlert() 
        }
        else
        {
            console.info('func is ', func)
            console.info('recorder is ', recorder )
            return func
        }
    }
    
    
    if ( window.SpeechRecognition || window.webkitSpeechRecognition )
    {
        noFeature.value = false
        recorder = new NativeFluidRecorder( lang )
        recorder.onSimpleResult( ( text ) => recorderChunk.value = text )
        
        return {
            noFeature,
            recorder,
            chunk: recorderChunk,
            start: noFeatureDecorator( recorder.start.bind( recorder ) ),
            stop: noFeatureDecorator( recorder.stop.bind( recorder ) ),
        }
    }    
    else
    {
        noFeature.value = true
        noFeatureAlert()
        return {
            noFeature,
            noFeatureAlert,
            recorder,
        }
    }
}