import { ref , onMounted, inject, watch } from 'vue'
// import { NativeSpeakerDriver } from "./drivers/nativespeakerdriver"
// import { AwsSpeakerDriver } from './drivers/awsspeakerdriver'

import { NativeSpeakerDriverv2 } from "./drivers/nativespeakerdriverv2"
import { AwsSpeakerDriverv2 } from './drivers/awsspeakerdriverv2'
import { AwsSpeakerDriverv3 } from './drivers/awsspeakerdriverv3'

import LocaleDriverManager from './drivers/localedrivermanager'
import { useSoundStore } from '../stores/soundstore'

export function useSpeakerManager( lang = 'pt-BR')
{
    const mute = ref( true )
    const soundStore = useSoundStore()
    const toggleMute = () => mute.value = !mute.value
    const manager = new LocaleDriverManager( lang )
    

    watch( () => soundStore.state , async (newValue, oldValue ) =>{
        // console.warn('useSpeakerManager watcher got change in soundStore ', newValue, oldValue )
        // console.warn( soundStore.getCredentials() )
        const awsSpeakerDriver = new AwsSpeakerDriverv2( soundStore.getCredentials(), lang )
        await awsSpeakerDriver.loadVoices()
        manager.registerDriver('cloud', awsSpeakerDriver )
    }, { deep:true, once:true  } )

    // console.info(' speaker manager soundStore is ', soundStore )
    // console.info('speaker manager sound store AWS credentials in ', soundStore.getCredentials() )
    /* add more drivers here */
    manager.registerDriver('native', new NativeSpeakerDriverv2( lang ) )
    // manager.registerDriver('cloud', new AwsSpeakerDriverv2( soundStore.getCredentials(), lang ) )
    // manager.registerDriver('cloud', new AwsSpeakerDriverv3( lang ) )

    onMounted( async () =>{
        Object.values(manager.drivers).forEach( async( driver ) =>{
            await driver.loadVoices()
            // console.info(`driver voice loaded`)
        })
    })




    return {
        mute,
        toggleMute,
        manager
    }


}