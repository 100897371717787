<template>
  <div class="chat-input">
    <input
      ref="inputField"
      type="text"
      v-model="message"
      @keyup.enter="sendMessage"
      placeholder="Digite sua mensagem..."
      :disabled="isLoading"
    />
    <button @click="sendMessage" :disabled="isLoading">
      <i class="fas fa-paper-plane"></i>
    </button>
    <button @click="toggleListening" :disabled="isLoading">
      <i :class="this.recorder && this.recorder.isRecording ? 'fas fa-microphone-slash' : 'fas fa-microphone'"></i>
    </button>
  </div>
</template>

<script>
import { useNativeRecorder } from './darknerd/composables/nativerecorder';
import { useToast } from 'vue-toastification';
export default {
  name: 'ChatInputv2',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      message: '',
      recognition: null,
      isListening: false,
      recorder:null,
      toaster : useToast()
    };
  },
  mounted() {
    this.initRecognition();
  },
  methods: {
    sendMessage() {
      if (this.message.trim() !== '') {
        this.$emit('sendMessage', this.message);
        this.message = '';
        this.$nextTick(() => {
          this.$refs.inputField.focus();
        });
      }
    },
    initRecognition() {

      this.recorder = useNativeRecorder('pt-BR',
        // onresult
        (transcript) => {
          this.message = transcript
          this.sendMessage()
        },
        // onstart
        null,
        //onend
        () => this.isListening = false,
        //onerror
        () => {
          this.isListening = false
          this.toaster.error('Problemas com a captura do áudio. Verifique as configurações desse aparelho e tente novamente por favor.')
        }
      )
      
    },
    toggleListening() {
      this.$emit('cancelSpeech');
      // console.log( this.recorder.noFeature)
      if( this.recorder && this.recorder.noFeature.value)
      {
          this.recorder.noFeatureAlert()
          return
      }
      else
      {
        this.recorder.toggleRecording()
      }
      
    },
  },
};
</script>

<style scoped>
.chat-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f1f1f1;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:disabled {
  background-color: #ccc;
}

.chat-input button i {
  font-size: 16px;
}

.chat-input button:last-child {
  margin-left: 10px;
}
</style>
