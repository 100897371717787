<script setup>

import { ref, onMounted } from 'vue'
import SpeakerProviderConfiguration from './components/SpeakerProviderConfiguration.vue';
import RecorderProviderConfiguration from './components/RecorderProviderConfiguration.vue';

const show = ref(false)
const toggleShow = () => show.value = !show.value

onMounted(() => console.log('configuration sidebar mounted') )



defineExpose({
    show,
    toggleShow,
})


</script>
<template>

<div 
    id="configuration-sidebar" 
    class="offcanvas offcanvas-end" 
    tabindex="-1" 
    aria-labelledby="configuration-title"
>
  <div class="offcanvas-header shadow mb-3 text-bg-primary">
    <h5 class="offcanvas-title" id="configuration-title">
        <i class='fas fa-cog me-1'></i>
        <span>
            Chat Configuration
        </span>
    </h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <SpeakerProviderConfiguration class='mb-3' />
    <RecorderProviderConfiguration />
  </div>
</div>



</template>