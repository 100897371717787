import ABCDriverManager  from './abcdrivermanager'
/*
    meio useless
*/
export default class LocaleDriverManager extends ABCDriverManager
{
    constructor( lang = 'pt-BR')
    {
        super()
        this.lang = lang
    }
}