import { createApp } from 'vue';
import { createPinia } from 'pinia'
import Toast from 'vue-toastification'

// import App from './App.vue';

import './assets/styles.css';
import './assets/dn-styles.css'
import 'vue-toastification/dist/index.css'

import Appv2 from './Appv2.vue'


const toastOptions ={}

const app = createApp(Appv2)

app
    .use( createPinia() )
    .use( Toast, toastOptions )
    .mount('#app');
