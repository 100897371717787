<script setup>

const props = defineProps({
    voices: { type:Array, required: true }
})

const emits = defineEmits(['onVoiceSelected'])


const localOnChange = ( evt ) =>{
    // console.info( 'local on change ' , evt.target.value )
    emits('onVoiceSelected', parseInt( evt.target.value ) )
}


</script>
<template>
    <select class='form-select' @change="localOnChange">
        <option v-if='voices.length' value = '-1' selected>Selecione uma voz</option>
        <option v-else value = '-1' selected>sem opções de vozes no momento</option>
        <option v-for="(voice, idx) in voices" :key="idx" :value="idx">
            {{  voice  }}
        </option>
    </select>
</template>