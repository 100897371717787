<script setup>

import { onMounted } from 'vue'
import anime from 'animejs'

onMounted( () =>{
    anime({
        targets: '.just-thinking i',
        opacity: [0,1],
        // translateY: [-10,1],
        duration: 1000,
        delay: anime.stagger(100),
        loop: true,
        direction: 'alternate',
    })
})


</script>
<template>
    <span class="just-thinking ms-3">
        <i class="fas fa-circle"></i>
        <i class="fas fa-circle"></i>
        <i class="fas fa-circle"></i>
    </span>
</template>
<style scoped>

.just-thinking i
{
    margin:auto 5px;
    font-size:35%;
}

</style>