import { ref , onMounted } from 'vue'
import ABCSpeakerDriverv2 from './abcspeakerdriverv2'
/*

    speaker - speechSynthetizer, play, pause, recceive message, load data if needed
    voiceManager - list valid voices

*/
const VOICES_RELOAD_TIME = process.env.VUE_APP_NATIVE_SPEAKER_RELOAD_VOICE_TIMER

export class NativeSpeakerDriverv2 extends ABCSpeakerDriverv2
{
    constructor( lang='pt-BR')
    {
        super( lang )
        this.synth = window.speechSynthesis
        
        // utterance default options
        this.speechOptions = {
            rate: 1.3,
            pitch:1,
            volume:1
        }

    }

    async loadVoices()
    {
        this.voices = this.synth.getVoices().filter( voice => voice.lang == this.lang )

        if ( this.voices.length > 0 )
            this.voice = this.voices[0]
        else
            setTimeout(() => this.loadVoices(), VOICES_RELOAD_TIME)
        return this
    }

    toArray()
    {
        return this.voices.map( voice => voice.name )
    }

    _buildUtter( message, onStart=null, onEnd=null )
    {
        const utter = new SpeechSynthesisUtterance( message )
        utter.lang = this.lang
        utter.voice = this.getVoice()
        utter.rate = this.speechOptions.rate
        utter.pitch = this.speechOptions.pitch
        utter.volume = this.speechOptions.volume

        if ( onStart )
            utter.onstart = onStart
        if ( onEnd )
            utter.onend = onEnd

        return utter
    }

    async read( message , onStart = null, onEnd = null)
    {
        const utter = this._buildUtter( message, onStart, onEnd )
        if ( this.isSpeaking() )
        {
            this.cancel()
            if ( onEnd )
                onEnd()
        }    

        setTimeout(()=> this.synth.speak(utter), 500 )
        return this
    }

    cancel()
    {
        // probable window.speechSynthesis.cancel
        // window.speechSynthesis.cancel()
        this.synth.cancel()
        return this
    }

    isSpeaking()
    {
        // probable window.speechSynthesis.speaking
        return this.synth.speaking
    }
}