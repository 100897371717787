export default class ABCDriverManager
{
    constructor()
    {
        this.drivers = {}
        // str key aqui pra forçar a usar o getDriver
        this.pointer = null
    }

    registerDriver( name, driver )
    {
        this.drivers[ name ] = driver
        if ( !this.pointer )
            this.pointer = name
        return this
    }

    selectDriver( name )
    {
        if ( Object.keys( this.drivers).includes( name ) )
            this.pointer = name
        return this.getDriver()
    }

    getDriver()
    {
        return this.drivers[ this.pointer ]
    }
}